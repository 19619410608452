import React from 'react'

const Footer = () => {
    return (
        <div className='flex absolute bottom-0 items-center justify-between p-6 lg:px-8'>
            <div>
                <span className='text-gray-300 md:text-lg text-sm'>
                    © 2024 Opermont and/or its affiliates.
                </span>
                {/* <span className='text-white border-b-[1px] boreder-white md:text-lg text-sm'>
                    Disclosure
                </span> */}
            </div>
        </div>
    )
}
export default Footer;



