import React from 'react';
import { ReactComponent as Name } from '../assets/images/OpermontHome.svg'
const HomePage = () => {
  return (
    <div className='overflow-hidden'>
      <div className='mx-auto flex flex-col md:p-6 p-4 lg:px-8 w-full'>
        <div
          className='border-gray-500 flex flex-wrap items-center border-[1px]  w-full text-center justify-center p-3 rounded-2xl font-semibold md:text-md text-sm text-gray-400'
          style={{
            background: 'linear-gradient(90.57deg, rgba(255, 255, 255, 0.09) -15.82%, rgba(255, 255, 255, 0.31) 112.75%)'
          }}
        >
          <span className='text-gray-400 md:text-lg text-sm '>
            Exciting developments are happening
          </span>
          <span className='text-gray-400 md:text-lg text-sm '>&nbsp; behind the scenes—</span>
          <span className='text-white md:text-lg text-sm font-semibold'>
            &nbsp;stay tuned!
          </span>
        </div>

        <Name className='md:mt-[100px] mt-10 h-full md:w-[70%] w-[90%]' />

        <div className='flex flex-col w-full mt-6'>
          <div className='flex flex-wrap items-center text-start'>
            <span className='text-gray-400 md:text-lg text-md '>
              Opermont (Digital Asset Ticker: OPER) is a
            </span>
            <span className='text-white md:text-lg text-sm font-semibold'>
              &nbsp;Research-Driven Integrated Investment
            </span>
          </div>
          <div className='flex flex-wrap items-center text-start'>
            <span className='text-gray-400 md:text-lg text-md '>
              Ecosystem that enables everyone to secure
            </span>
            <span className='text-white md:text-lg text-md font-semibold'>
              &nbsp;early access to the digital economy.
            </span>
          </div>
        </div>

        <div className='flex md:flex-row flex-col justify-between mt-10'>
          <div className='flex flex-col'>
            <span className='text-white md:text-lg text-md'>Global</span>
            <span className='text-white md:text-lg text-md'>Communications</span>
          </div>
          <div className='md:mt-0 mt-5'>
            <a href='mailto:media@opermont.com'>
              <button
                className='px-5 py-4 text-white rounded-xl w-full'
                style={{
                  background: 'linear-gradient(90.57deg, #0052FE99 -15.82%, #0052FE99 112.75%)'
                }}
              >
                media@opermont.com
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePage;
