'use client'

import { useState } from 'react'
import {
  Dialog,
  DialogPanel,
} from '@headlessui/react'
import {
  Bars2Icon,
  XMarkIcon
} from '@heroicons/react/24/outline'

import { ReactComponent as Logo } from '../assets/images/Opermont.svg';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-transperent">
      <nav aria-label="Global" className="mx-auto flex w-full items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <Logo />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars2Icon  aria-hidden="true" className="h-6 w-6 text-white" />
          </button>
        </div>
        {/* <PopoverGroup className="hidden lg:flex lg:gap-x-12">

          <a href="#" className="text-sm font-semibold leading-6 text-white">
            Features
          </a>
          <a href="#" className="text-sm font-semibold leading-6 text-white">
            Marketplace
          </a>
          <a href="#" className="text-sm font-semibold leading-6 text-white">
            Company
          </a>
        </PopoverGroup> */}
        <div className="hidden flex lg:flex lg:flex-1 lg:justify-end">
          <button title="Updating soon" className="text-sm font-semibold leading-6 text-white bg-black rounded-full px-4 py-2 mr-3">
            Team
          </button>
          <a href='mailto:info@opermont.com' className="text-sm font-semibold leading-6 text-white bg-customBlue rounded-full px-4 py-2">
            Contact
          </a>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <Logo />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <div className='flex flex-col'>

                <button title="Updating soon" className="text-sm font-semibold leading-6 text-white bg-black rounded-full px-4 py-2 mb-4">
                  Team
                </button>
                <a href='mailto:info@opermont.com' className="text-sm text-center font-semibold leading-6 text-white bg-customBlue rounded-full px-4 py-2">
                  Contact
                </a>
                </div>
                {/* <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Features
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Marketplace
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Company
                </a> */}
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
export default Header;
