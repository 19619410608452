import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Header from './component/Header';
import HomePage from './Pages/HomePage';
import Footer from './component/Footer';

function App() {
  const backgroundImage = require('./assets/images/bg.jpg')
  return (
    <div className='flex flex-col h-[100vh] w-full bg-cover bg-center items-center' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='md:w-[80%] w-[95%]'>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} />
        </Routes>
        <Footer />
      </Router>
      </div>
    </div>
  );
}

export default App;
